import React, { useState, useEffect, useLayoutEffect } from 'react';
import FooterTwo from '../common/elements/footer/FooterTwo';
import HeadTitle from '../common/elements/head/HeadTitle';
import HeaderFive from '../common/elements/header/HeaderFive';
import PostSectionThirteen from '../common/components/post/PostSectionThirteen';
import PostSectionFour from '../common/components/post/PostSectionFour';
import PostSectionSeven from '../common/components/post/PostSectionSeven';
import WidgetPostList from "../common/components/sidebar/WidgetPostList";
import TagListSlide from "../common/components/tag/TagListSlide";
import PostLayoutTwo from "../common/components/post/layout/PostLayoutTwo";
import { client } from '../../lib/apollo';
import { gql } from "@apollo/client";
import { getSelectorsByUserAgent } from "react-device-detect"

const HomeDefault = ({ posts, hotTag, categories, highlightPosts, popularPosts, seo, isMobile }) => {

  const [newPosts, setNewPosts] = useState(posts);

  const [pageNumber, setPageNumber] = useState(0);

  const [loading, setLoading] = useState(false);

  const [scrollDir, setScrollDir] = useState(false);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? false : true);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);

  const postsPerPage = 5;

  useEffect(() => {
    if (pageNumber === 0) return;
    setLoading(true);

    const getNewPosts = async () => {
      const response = await client.query({
        query: gql`
        query GetNewPosts {
          posts(where: {orderby: {field: DATE, order: DESC}, taxQuery: {taxArray: [{taxonomy: CATEGORY, field: SLUG, terms: ["noi-bat"], includeChildren: false, operator: NOT_IN}, {taxonomy: CATEGORY, field: SLUG, includeChildren: false, operator: EXISTS}]}}, first: ${postsPerPage}, after: "${newPosts.props.endCursor}") {
            nodes {
              postId
              date
              slug
              title
              categories {
                nodes {
                  name
                  slug
                }
              }
              featuredImage {
                node {
                  altText
                  sourceUrl
                }
              }
              seo {
                opengraphDescription
              }
            }
            pageInfo {
              endCursor
              hasNextPage
            }
          }
        }
      `
      });
      const nextPosts = response?.data?.posts?.nodes;
      setNewPosts({
        props: {
          posts: [...newPosts.props.posts, ...nextPosts],
          endCursor: response?.data?.posts?.pageInfo?.endCursor,
          hasNextPage: response?.data?.posts?.pageInfo?.hasNextPage
        }
      })
      setLoading(false);
    }
    getNewPosts()
  }, [pageNumber]);

  const checkLGLayout = () => {
    if (window.innerWidth < 1200) {
      return true;
    }
    return false;
  }

  const handleScroll = () => {
    const sidebar = document.getElementById('sidebar');
    const lastWidget = sidebar.lastElementChild;
    const lastWidgetAbove = lastWidget.previousElementSibling?.getBoundingClientRect().bottom || sidebar.getBoundingClientRect().top;
    if (lastWidgetAbove < (checkLGLayout() ? 120 : 90) && !scrollDir) {
      lastWidget.classList.add('fixed');
    }
    if (lastWidgetAbove > (checkLGLayout() ? 120 : 90) && scrollDir) {
      lastWidget.classList.remove('fixed');
    }
    if (lastWidget.getBoundingClientRect().bottom > sidebar.getBoundingClientRect().bottom && !scrollDir) {
      lastWidget.classList.add('absolute');
    }
    if (lastWidget.getBoundingClientRect().top > (checkLGLayout() ? 120 : 90) && !scrollDir) {
      lastWidget.classList.remove('absolute');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [scrollDir]);

  useEffect(() => {

    if (document.getElementById('pc-masthead') && document.getElementById('pc-masthead').offsetHeight === 0 && process.env.NEXT_PUBLIC_ADS_HOMEPAGE_MASTHEAD) {
      document.getElementById('pc-masthead').innerHTML = `
      <div class='d-none d-lg-block mt--80 mt_lg--120 mt_md--120 mt_sm--120 mt_mobile--120'>
        <iframe style="position: relative; left: 50%;transform: translateX(-50%)" src=${process.env.NEXT_PUBLIC_ADS_HOMEPAGE_MASTHEAD} width="970" height="250" marginWidth="0" marginHeight="0" frameBorder="0" scrolling="no"></iframe>
      </div>
      `
    }
    const sidebar = document.getElementById('sidebar');
    const lastWidget = sidebar.lastElementChild;
    lastWidget.classList.remove('fixed');
  }, []);

  // useLayoutEffect(() => {
  //   handleScroll();
  // }, []);

  useEffect(() => {
    handleScroll();
    var script = document.createElement('script');
    script.innerHTML = `
      var category = 'home';
    `;
    script.id = "state";
    document.body.appendChild(script);
  }, []);

  // useEffect(() => {
  // if (!mobileCheck()) {
  //   var script1 = document.createElement('script');
  //   script1.innerHTML = `
  //       var bsWrapAds = bsWrapAds || []; bsWrapAds.push(["deacf352f872af99d67ef8172c59cfd2"]);
  //     `;
  //   script1.id = "bs-wrap-popup";
  //   document.getElementById('content').appendChild(script1);
  //   var script = document.createElement('script');
  //   script.src = "//static.blueseed.tv/ajs/bs.rigel.instercroller.js";
  //   script.id = "bs-deacf352f872af99d67ef8172c59cfd2";
  //   document.getElementById('content').appendChild(script);

  //   var script2 = document.createElement('script');
  //   script2.innerHTML = `
  //     var bsWrapAds = bsWrapAds || []; bsWrapAds.push(["bfd813b99e638b55f29fd9e82d78995a"]);
  //     `;
  //   script2.id = "bs-wrap-masthead";
  //   document.getElementById('pc-masthead').appendChild(script2);
  //   var script3 = document.createElement('script');
  //   script3.src = "//static.blueseed.tv/ajs/bs.rigel.instercroller.js";
  //   script3.id = "bs-bfd813b99e638b55f29fd9e82d78995a";
  //   document.getElementById('pc-masthead').appendChild(script3);

  // } else {
  //   var script2 = document.createElement('script');
  //   script2.innerHTML = `
  //     var bsWrapAds = bsWrapAds || []; bsWrapAds.push(["f6fcc084b2242b15db982eda47b93018"]);
  //     `;
  //   script2.id = "bs-wrap-masthead";
  //   document.getElementById('mb-masthead').appendChild(script2);
  //   var script3 = document.createElement('script');
  //   script3.src = "//static.blueseed.tv/ajs/bs.rigel.instercroller.js";
  //   script3.id = "bs-f6fcc084b2242b15db982eda47b93018";
  //   document.getElementById('mb-masthead').appendChild(script3);
  // }
  // }, []);

  return (
    <>
      <div className="container content d-none d-sm-block" id='content'>
        {
          !isMobile &&
          <div id='pc-masthead' className='mt--80 mt_lg--120 mt_md--120 mt_sm--120 mt_mobile--120'>
          </div>
        }
        <HeadTitle seo={seo} />
        <HeaderFive />
        <div className={`page mt_md--120 mt_sm--120 mt_mobile--120 ${process.env.NEXT_PUBLIC_ADS_HOMEPAGE_MASTHEAD ? "mt--10 mt_lg--20" : "mt--80 mt_lg--120"}`}>
          <div className="page-content">
            <div className="row">
              <div className="col">
                <PostSectionThirteen postData={newPosts} pClass="" />
                <TagListSlide tagData={hotTag} />
                <PostLayoutTwo dataPost={newPosts} />
                {
                  newPosts.props.hasNextPage &&
                  <div className="post-button">
                    <div
                      className="axil-button button-rounded color-foundation-primary-color-normal mt--24"
                      aria-label="Xem thêm bài viết"
                      onClick={() => setPageNumber(pageNumber + 1)}
                    >
                      Xem thêm bài viết
                      {
                        loading && <div className="spinner-border text-light" style={{ width: 15, height: 15 }} role="status">
                        </div>
                      }
                    </div>
                  </div>
                }
                <PostSectionFour dataPost={highlightPosts} page="home" />
                <PostSectionSeven categoryData={categories} currentCategory='hoa-hau' />
                <PostSectionSeven categoryData={categories} currentCategory='life-style' />
                <PostSectionSeven categoryData={categories} currentCategory='giai-tri' />
                {/* <PostSectionSeven categoryData={categories} currentCategory='short-clip' /> */}
              </div>
            </div>
          </div>
          <div className="sidebar" id="sidebar">
            <WidgetPostList postData={popularPosts} lastElement={process.env.NEXT_PUBLIC_ADS_HOMEPAGE_300x600?.trim() === ''} />
            {
              process.env.NEXT_PUBLIC_ADS_HOMEPAGE_300x600?.split(',').length > 1 && process.env.NEXT_PUBLIC_ADS_HOMEPAGE_300x600?.split(',').slice(1).map((item, index) => {
                if (item) {
                  return (
                    <div className='mb--30' key={index}>
                      <iframe src={item} width="300" height="600" marginWidth="0" marginHeight="0" frameBorder="0" scrolling="no"></iframe>
                    </div>
                  )
                }
              })
            }
          </div>
        </div>
      </div>
      <div className="content d-block d-sm-none">
        {
          isMobile && <div id='mb-masthead' className='mt--80 mt_lg--115 mt_md--115 mt_sm--115 mt_mobile--115'></div>
        }
        <HeadTitle seo={seo} />
        <div className="container mt--15">
          <HeaderFive />
        </div>
        <div className="page">
          <div className="page-content">
            <div className="row">
              <div className="col">
                <div className="container">
                  <PostSectionThirteen postData={newPosts} pClass="" />
                </div>
                <TagListSlide tagData={hotTag} />
                <div className="container">
                  <PostLayoutTwo dataPost={newPosts} isMobile={isMobile} />
                  {
                    newPosts.props.hasNextPage &&
                    <div className="post-button">
                      <div
                        className="axil-button button-rounded color-foundation-primary-color-normal mt--24"
                        aria-label="Xem thêm bài viết"
                        onClick={() => setPageNumber(pageNumber + 1)}
                      >
                        Xem thêm bài viết
                        {
                          loading && <div className="spinner-border text-light" style={{ width: 15, height: 15 }} role="status">
                          </div>
                        }
                      </div>
                    </div>
                  }
                  <PostSectionFour dataPost={highlightPosts} page="home" />
                  <PostSectionSeven categoryData={categories} currentCategory='hoa-hau' />
                  <PostSectionSeven categoryData={categories} currentCategory='life-style' />
                  <PostSectionSeven categoryData={categories} currentCategory='giai-tri' />
                  {/* <PostSectionSeven categoryData={categories} currentCategory='short-clip' /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {
          isMobile && <div id="mb-inflow"></div>
        } */}
      </div>
      <FooterTwo bgColor="bg-color-grey" />
      <div id="ads-mobile" className='d-none d-sm-block'></div>
    </>
  );
}

export default HomeDefault;


// export async function getStaticProps() {
export async function getServerSideProps({ req, res }) {
  const userAgent = req.headers['user-agent'] || '';
  const { isMobile } = getSelectorsByUserAgent(userAgent);

  const posts = await getNewPosts()

  const hotTag = await getHotTag()

  const categories = await getCategory()

  const highlightPosts = await getHighlightPosts()

  const popularPosts = await getPopularPosts()

  const seo = await getSeo()

  return {
    props: {
      posts,
      hotTag,
      categories,
      highlightPosts,
      popularPosts,
      seo,
      isMobile
    }
  }
}

export async function getNewPosts() {
  const GET_NEW_POSTS = gql`
    query GetNewPosts {
      posts(where: {orderby: {field: DATE, order: DESC}, taxQuery: {taxArray: [{taxonomy: CATEGORY, field: SLUG, terms: ["noi-bat"], includeChildren: false, operator: NOT_IN}, { taxonomy: CATEGORY, field: SLUG, terms: ["demo"], includeChildren: false, operator: NOT_IN }, {taxonomy: CATEGORY, field: SLUG, includeChildren: false, operator: EXISTS}]}}, first: 11) {
        nodes {
          postId
          date
          slug
          title
          categories {
            nodes {
              name
              slug
            }
          }
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          seo {
            opengraphDescription
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_NEW_POSTS
  });
  const posts = response?.data?.posts?.nodes;
  const endCursor = response?.data?.posts?.pageInfo?.endCursor;
  const hasNextPage = response?.data?.posts?.pageInfo?.hasNextPage;
  return {
    props: {
      posts,
      endCursor,
      hasNextPage
    }
  }
}

export async function getHotTag() {
  const GET_HOT_TAG = gql`
    query GetHotTag {
      popularTags(first: 10) {
        nodes {
          count
          imageUrl
          name
          slug
          tagViewsCount
          databaseId
          posts(first: 1) {
            nodes {
              categories {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_HOT_TAG
  });
  const tags = response?.data?.popularTags?.nodes;

  return {
    props: {
      tags
    }
  }
}

export async function getCategory() {
  const GET_CATEGORY = gql`
    query GetCategory {
      categories {
        nodes {
          categoryId
          name
          slug
          children {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `
  const response = await client.query({
    query: GET_CATEGORY
  })
  const categories = response?.data?.categories?.nodes
  return {
    props: {
      categories
    }
  }
}

export async function getHighlightPosts() {
  const GET_HIGHLIGHT_POSTS = gql`
    query GetHighlightPosts {
      posts(
        where: {orderby: {field: DATE, order: DESC}, categoryName: "Nổi bật"}
        first: 4
      ) {
        nodes {
          postId
          date
          slug
          title
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          seo {
            opengraphDescription
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `;
  const response = await client.query({
    query: GET_HIGHLIGHT_POSTS
  });
  const posts = response?.data?.posts?.nodes;
  return posts
}

export async function getPopularPosts() {
  const GET_POPULAR_POSTS = gql`
    query GetPopularPosts {
      popularPosts(first: 7) {
        nodes {
          title
          postViewsCount
          slug
          featuredImage {
            node {
              altText
              sourceUrl
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  `
  const response = await client.query({
    query: GET_POPULAR_POSTS
  })
  const posts = response?.data?.popularPosts?.nodes
  return posts
}

export async function getSeo() {
  const GET_SEO = gql`
    query GetSEO {
      page(id: "trang-chu", idType: URI) {
        seo {
          fullHead
          title
          opengraphDescription
        }
      }
    }
  `
  const response = await client.query({
    query: GET_SEO
  })
  const seo = response?.data?.page?.seo
  return seo
}


